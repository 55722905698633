import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'routes/routes';
import { ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import ReactGA4 from 'react-ga4';

import { defaultTheme } from './themes/default';
import store from 'store/store';

import './i18n';
import './index.scss';

let theme = createTheme();
theme = responsiveFontSizes(defaultTheme);

const cache = createCache({
  key: 'meraxis',
  nonce: process.env.REACT_APP_API_NONCE,
  prepend: true,
});

if (process.env.NODE_ENV !== 'production') {
  const meta = document.createElement('meta');
  meta.name = 'robots';
  meta.content = 'noindex';
  document.head.appendChild(meta);
}

if (process.env.NODE_ENV === 'production') {
  ReactGA4.initialize('G-P32E24F8GV');
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ReduxProvider store={store}>
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  </ReduxProvider>
);
